import { loadConfig, ValidateError } from '@versori/spa-server/client';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { defaultConfig } from './config/default-config';

async function main() {
    await loadConfig(defaultConfig).catch((err) => {
        // eslint-disable-next-line no-console
        console.error('loadConfig() failed', err instanceof ValidateError ? err.messages : err.message);
        throw err;
    });

    const App = lazy(() => import(/* webpackChunkName: "app" */ './App'));

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    root.render(
        <React.StrictMode>
            <Suspense>
                <App />
            </Suspense>
        </React.StrictMode>
    );
}

main().then();
